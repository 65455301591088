.custom-ngx-slider ngx-slider {
  .ngx-slider-pointer {
    background: #ED812C;
  }

  .ngx-slider-tick {
    background: #9499A8;
    &.ngx-slider-selected {
      background: #9499A8;
    }
  }

  .ngx-slider-bar {
    background: #DEE0E4;
  }

}

.calc__details-line {
  color: #172134;
  opacity: .25;
}


.calculator-company-address {
  background: #3C4A64;
  color: #FFFFFF;
  border-radius: 0 0 6px 6px;

  p {
    color: #FFFFFF !important;
  }
}

.calculator-details {
  background: #F1F1EE;
  font-size: 18px !important;
}

.calculator-emphasis-font {
  font-size: 22px !important;
}