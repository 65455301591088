@use '@angular/material' as mat;
@import './sk-colors';

.custom-ngx-slider ngx-slider {
  .ngx-slider-pointer {
    background: #63DCE5;
  }

  .ngx-slider-tick {
    background: #A5A9AA;

    &.ngx-slider-selected {
      background: #A5A9AA;
    }
  }

  .ngx-slider-bar {
    background: #D8D8D8;
  }

}

.calc__details-line {
  color: #172134;
  opacity: .25;
}

.calculator-company-address {
  background: #164666;
  color: #FFFFFF;
  border-radius: 0 0 6px 6px;

  p {
    color: #FFFFFF !important;
  }
}

.show-plans__bottom {
  background: mat.m2-get-color-from-palette($sk-grey, 100);
}

.calc__n-monthly-payments-value {
  color: #164666;
}

