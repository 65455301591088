label {
  margin-bottom: 0; // 2
}

a:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-fab):not(.mat-mdc-mini-fab):not([mat-list-item]):not(.nav-link) {
  color: #253452; // 3
}

body {
  font-family: 'Assistant', sans-serif !important;
  font-size: 16px;
}

button {
  text-transform: uppercase;
  font-size: 16px !important;
}

.mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-outlined-button {
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: normal !important;
  min-height: 56px !important;
}