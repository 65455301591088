@use '@angular/material' as mat;
@import './sk-colors';
@import './variables';

.navbar {
  padding: 0;
}

.nav-link {
  color: mat.m2-get-color-from-palette($sk-color, 500) !important;
  font-family: 'VWAGTheSans-Bold', sans-serif;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// overrides the one from styles.scss
a.nav-link.active {
  color: mat.m2-get-color-from-palette($sk-grey, 500) !important;
}

.navbar-logout {
  color: mat.m2-get-color-from-palette($sk-color, 500) !important;
}

li.nav-partner {
  color: mat.m2-get-color-from-palette($sk-grey, 500) !important;
}

.logo {
  background: url("../../assets/logos/logo_desktop.svg") 0 0 no-repeat padding-box;
  width: 230px;
  height: 138px;
  @media screen and (max-width: $phone) {
    background: url("../../assets/logos/logo_mobile.svg") 0 0 no-repeat padding-box;
    width: 165px;
    height: 47px;
  }
}