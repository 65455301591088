// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import '../assets/fonts/assistant.css';
@import "./variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/card";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import '../assets/material-icon-fonts/material-icons.css';
@import "./_navbar.scss";
@import "./_animations.scss";
@import "./_xs2a-overrides";
@import "./_reset";
@import "./rate-calculator";
@import './shared/variables';

/*
  STYLES
 */
body {
  padding-bottom: 60px !important;
}

.card {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  .card-body.pb-5 {
    padding-bottom: 4rem !important;
  }

  button.par-card-button {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    white-space: normal;
    min-height: 56px !important;
  }

  a.par-card-link {
    position: absolute;
    line-height: 36px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
    font-weight: bold !important;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &.card-gradient {
    border: none;
    background: linear-gradient(#0077D5, theme-color('primary'));
    color: #ffffff !important;

    a {
      color: #ffffff !important;

      &:hover, &:focus {
        color: #ffffff !important;
      }
    }
  }
}

a {
  cursor: pointer !important;

  &:hover:not(.nav-link) {
    text-decoration: underline !important;
  }

  &.link {
    text-transform: uppercase;
    font-weight: bolder;
  }
}

mat-form-field.btn-suffix {
  .mat-mdc-form-field-icon-suffix {
    right: 5px !important;
    top: 10px !important;
    position: absolute !important;
  }

  button {
    min-width: 60px !important;
    padding-right: 0;
    padding-left: 0;
  }

  input, select, mat-select {
    padding-right: 80px;
  }

}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-bar {
  margin-right: 1rem !important;
}

.card-title {
  color: theme-color('primary');
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  .operator {
    .material-icons {
      font-size: 36px !important;
      line-height: 54px !important;
      cursor: pointer;
    }
  }
}

.clickable {
  cursor: pointer;
}

.hint {
  color: theme-color('info');
  font-size: 16px !important;
}

a.nav-link.active {
  color: theme-color('primary') !important;
}

.listview {
  list-style-type: none;
  padding-left: 0;

  .listview-element {
    padding: 10px 5px;

  }

  &.bordered {
    .listview-element {
      border-bottom: 1px solid #EEEEEE;
    }

    .listview-element:last-child {
      border-bottom: none;
    }
  }
}

.fee-warning {
  margin: -21px -20px 20px -20px;
  padding: 20px;
  background: theme-color('warning');
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  textarea,
  input {
    font-size: 16px;
  }

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

ul.basket {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.toggler-right {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
label.mat-checkbox-layout {
  white-space: normal !important;
}

.back {
  position: absolute !important;
  left: 0;
  top: 6px;
}

hr {
  border: none;
  border-bottom: 1px solid #757575;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

.logo {
  background: url("../assets/logos/logo.svg") 0 0 no-repeat padding-box;
  width: 160px;
  height: 40px;
  margin: auto;
}

.calc__chevron {
  margin-top: 9px;
  margin-bottom: 1px;
}

.calc__chevron--size {
  font-size: 36px;
}

.calc__chevron--spacing {
  margin-top: 7px;
  margin-bottom: -6px;
}

.calc__desired-amount-label {
  font-size: 22px !important;
  font-weight: bold;
}

.calc__desired-amount {
  color: theme-color('primary');
  font-size: 30px !important;
}

#calc-desired-amount-link {
  text-decoration: none !important;
}

.calc__desired-amount-button {
  right: 8px !important;
  top: 10px !important;
}

.calc-desired-amount-underline {
  margin-top: 5px;
  width: 137px;
}

.calc__repay-in-label {
  font-size: 18px !important;
}

.calc__repay-in-value {
  font-size: 36px !important;
  font-weight: 600;
}

.calc__repay-in-month {
  font-size: 22px !important;
  font-weight: 600;
}

.show-plans__bottom {
  margin-top: 24px;
  padding-top: 18px;
  padding-bottom: 5px;
}

.show-plans__details {
  margin-bottom: 14px;
}

.show-plans__disclaimer {
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 8px;
}

.show-plans__company-address {
  border-radius: 0 0 6px 6px;
  padding: 17px 30px;
}

.custom-ngx-slider .ngx-slider {
  margin-top: 60px;
  margin-bottom: 12px;

  .ngx-slider-tick-value {
    margin-top: -12px;
  }

  .ngx-slider-bar {
    border-radius: 10px;
    height: 20px;
    margin-right: -10px;
    margin-top: -8px;
  }

  .ngx-slider-pointer {
    border-radius: 20px;
    margin-left: -2px;
    margin-top: -4px;
    height: 38px;
    width: 38px;

    &:after {
      display: none;
    }
  }

  .ngx-slider-inner-tooltip {
    font-size: 18px;
  }
}

.calc__n-monthly-payments-value {
  font-size: 30px !important;
  font-weight: 600;
}

.calc__details-line {
  margin-top: 16px;
  margin-bottom: 10px;
}

.calc__details-1st-column {
  font-size: 18px;
  text-align: left;
}

.calc__details-2nd-column {
  text-align: left;
  font-size: 18px;
}

body.bg-transparent {
  padding-bottom: 0 !important;
}

change-rate {
  .calc__repay-in-label {
    font-size: 16px !important;
  }

  .calc__repay-in-value {
    font-size: 28px !important;
  }

  .calc__repay-in-month {
    font-size: 18px !important;
  }

  .calc__n-monthly-payments-value {
    font-size: 17px !important;
  }

  .row {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}

.text-light {
  color: #757575 !important;
}

.primary-icon {
  color: #212529 !important;
}

.text-light.primary-icon {
  color: #212529 !important;
}

:root {
  --light-grey: #EEEEEE;
}