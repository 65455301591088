@use '@angular/material' as mat;
@import './sk-colors';
@import './variables';

:is(h1, h2, h3, h4, h5, h6) {
  color: mat.m2-get-color-from-palette($sk-color, 500);
  font-weight: bold;
  font-family: 'VWAGTheSans-Bold', sans-serif;
}

h1 {
  color: mat.m2-get-color-from-palette($sk-color, 500);
  //font-size: 60px;
  @media screen and (max-width: $tablet) {
    //font-size: 48px;
  }
  @media screen and (max-width: $phone) {
    //font-size: 32px;
  }
}

h2 {
  //font-size: 48px;
  @media screen and (max-width: $tablet) {
    //font-size: 36px;
  }
  @media screen and (max-width: $phone) {
    //font-size: 28px;
  }
}

h3 {
  //font-size: 36px;
  @media screen and (max-width: $tablet) {
    //font-size: 30px;
  }
  @media screen and (max-width: $phone) {
    //font-size: 24px;
  }
}

h4 {
  //font-size: 30px;
  @media screen and (max-width: $tablet) {
    //font-size: 22px;
  }
  @media screen and (max-width: $phone) {
    //font-size: 20px;
  }
}

body {
  color: mat.m2-get-color-from-palette($sk-color, 500);
  font-family: 'VWAGTheSans-Regular', sans-serif !important;
  //font-size: 18px;
}

p:not(.text-danger) {
  color: mat.m2-get-color-from-palette($sk-grey, 900);
  font-family: 'VWAGTheSans-Regular', sans-serif !important;
  //font-size: 18px;
}

.text-danger {
  color: $red-alert;
}

p.m-0 {
  color: white;
  //font-size: 14px;
}


td, dd, ul, li {
  color: mat.m2-get-color-from-palette($sk-grey, 900) !important;
}

dt {
  color: mat.m2-get-color-from-palette($sk-color, 500) !important;
}

th {
  text-transform: uppercase !important;
  color: mat.m2-get-color-from-palette($sk-color, 500) !important;
}

.text-light {
  color: mat.m2-get-color-from-palette($sk-grey, 500) !important;
}

div {
  color: mat.m2-get-color-from-palette($sk-grey, 900);
}